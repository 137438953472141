<template>
    <div class="container" v-show="loadingEvents || events.length > 0">
        <div class="events">
            <div class="row">
                <div class="col-12">
                    <GeneralMinTitle :title="t('events.title')" />
                </div>
                <div class="col-12" v-if="loadingEvents">
                    <div class="events__skeleton">
                        <Skeleton height="15rem"></Skeleton>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <Swiper
                        :slides-per-view="1"
                        slidesPerView="1"
                        :slidesPerView="'auto'"
                        :dir="rtl"
                        :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
                        :pagination="{ clickable: true }"
                        :modules="[SwiperPagination, SwiperAutoplay]"
                        :parallax="true"
                        spaceBetween="30"
                        pagination
                        :breakpoints="{
                            '442': {
                                slidesPerView: 1,
                            },
                            '640': {
                                slidesPerView: 1,
                            },
                            '993': {
                                slidesPerView: 2,
                            },
                            '1024': {
                                slidesPerView: 2,
                            },
                        }"
                    >
                        <swiper-slide v-for="item in events" :key="item.id">
                            <div class="events-card">
                                <p @click="copyLink(item.id)" class="copy-link">
                                    <i class="pi pi-link"></i>
                                </p>
                                <div
                                    class="events-card__image-box"
                                    :style="`background-image: url(${item.banner});`"
                                ></div>

                                <div class="events-card__content-box">
                                    <h5 class="date">
                                        {{ formateDate(item.start_date) }}
                                    </h5>
                                    <div class="flex-container">
                                        <div>
                                            <LocLink class="name" :to="`/events/${item.id}`">
                                                {{ item.title }}
                                            </LocLink>
                                            <p class="location">
                                                {{ item.location }}
                                            </p>
                                            <p class="duration">
                                                {{ formateDate(item.start_date) }} - {{ formateDate(item.end_date) }}
                                            </p>
                                        </div>
                                        <LocLink :to="`/events/${item.id}`">
                                            <div class="arrow-box">
                                                <i class="pi pi-arrow-left" v-if="rtl"></i>
                                                <i class="pi pi-arrow-right" v-else></i>
                                            </div>
                                        </LocLink>
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                    </Swiper>
                </div>
            </div>
        </div>
    </div>
    <Toast />
</template>
<script setup>
import { useAxios } from '@/composables/useAxios';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';

const toast = useToast();
const events = ref([]);
const { t, locale } = useI18n();
const loadingEvents = ref(true);
const { api } = useAxios();

const rtl = computed(() => locale.value === 'ar');

onMounted(async () => {
    await nextTick();
    loadingEvents.value = true;
    const res = await api.get('events?sort=-start_date');
    events.value = res.data?.data;
    loadingEvents.value = false;
});

const copyLink = async id => {
    let link = location.href + 'events/' + id;
    try {
        await navigator.clipboard.writeText(link);
        toast.add({
            severity: 'success',
            summary: t('events.eventLink'),
            detail: t('copydone'),
            life: 2000,
        });
    } catch (err) {
        console.log(err);
    }
};

const formateDate = date => {
    let formattedDate = new Date(date);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    formattedDate = formattedDate.toLocaleDateString('en-GB', options);
    return formattedDate;
};
</script>

<style lang="scss">
.events {
    .events-card {
        // width: 450px;
        min-height: 500px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .copy-link {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 99;
            @include dir(rtl) {
                right: unset;
                left: 20px;
            }
            background-color: #e9e5f7;
            border-radius: 0.625rem;
            color: #1f299c;
            padding: 0.625rem;
            cursor: pointer;
            i {
                font-size: 1.25rem;
            }
        }
        &__image-box {
            width: 100%;
            height: 60%;
            position: absolute;
            top: 0;
            left: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &__content-box {
            width: 100%;
            height: 40%;
            position: absolute;
            top: 60%;
            left: 0;
            padding: 1.2rem;
            background-color: #1f299c;
            .flex-container {
                display: flex;
                justify-content: space-between;
                align-items: end;
            }
            .date {
                color: #fff;
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 0.625rem;
            }
            .name {
                color: #fff;
                font-size: 1.5rem;
                font-weight: 800;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .location {
                color: #fff;
                font-size: 1rem;
                font-weight: 700;
                margin-top: 0.625rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .duration {
                color: #fff;
                font-size: 1rem;
                font-weight: 700;
            }
            .arrow-box {
                i {
                    color: #fff;
                    font-size: 1.875rem;
                }
                @include dir(rtl) {
                    transform: scaleX(-1);
                }
            }
        }
    }
}
</style>
